<template>
  <Modal addClass="modal-pin">
    <div class="modal-body showorhidden">
      <h5>{{ title }}</h5>

      <span class="channel-switch">{{ pinMask }}</span>

      <div v-show="progressbar" class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>
  </Modal>
</template>

<script>
const pinDefaultValue = "1234";
import { setTimeOutParentalControl } from '@/helpers/parentalControl';

export default {
  name: "modalPin",

  data() {
    return {
      oldPin: "", // Utilizado para cuando se confirma la contraseña
      pin: "",

      progressbar: false, // Muestra u oculta la barra de carga

      status: "CHECK_DEFAULT", // CHECK_DEFAULT: Chequear si tiene PIN default,
      // ENTER_PIN: Solicita ingresar el PIN del usuario,
      // VALIDATE_PIN: Valida el PIN ingresado con respecto a Alpha,
      // REQUIRES_NEW_PIN: Una vez validado solicita el ingreso del nuevo PIN (si corresponde),
      // REQUIRES_NEW_PIN_CONFIRMATION: Requiere el ingreso de la confirmacion del nuevo PIN,
      // VALIDATE_NEW_PIN: Requiere el ingreso de la confirmacion del nuevo PIN,
      // SAVE_NEW_PIN: Guarda en Alpha el nuevo PIN,

      onSuccess: null, // Contiene la función que será llamada si todo sale correctamente

      onClose: null, // Contiene la función que será llamada si el usuario cancela o cierra el modal sin ingresar clave
      execCloseCallback: true, // Sirve para verificar si debo llamar a la función de callback de cierre de modal
    };
  },

  components: {
    Modal: () => import("@/components/Modal.vue"),
  },

  mounted() {
    this.$bus.$on("modal-pin:show", this.init);

    this.$bus.$on("modal-pin:showReset", this.initReset);
  },

  beforeDestroy() {
    this.$el.removeEventListener("keyup", this.keyboardControls);

    this.$bus.$off("modal-pin:show", this.init);

    this.$bus.$off("modal-pin:showReset", this.initReset);
  },

  computed: {
    title() {
      if (
        this.status === "CHECK_DEFAULT" ||
        this.status === "ENTER_PIN" ||
        this.status === "VALIDATE_PIN"
      ) {
        return "Ingrese PIN de Control parental";
      }

      if (this.status === "REQUIRES_NEW_PIN") {
        return "Ingrese un nuevo PIN de Control parental";
      }

      if (
        this.status === "REQUIRES_NEW_PIN_CONFIRMATION" ||
        this.status === "VALIDATE_NEW_PIN" ||
        this.status === "SAVE_NEW_PIN"
      ) {
        return "Confirme su nuevo PIN de Control parental";
      }
    },

    pinMask() {
      return this.pin.replace(/[0-9]/g, "*").padEnd(4, "_");
    },
  },

  methods: {
    init(onSuccess, onClose) {
      if (typeof onSuccess === "function") {
        // if (this.$route.name !== 'parental-control') {
        //     telecentro.tplay.core.biz.parentalControl.setAge(false, 0);
        // }
        this.onSuccess = onSuccess;
      } else {
        this.onSuccess = null;
      }

      if (typeof onClose === "function") {
        this.onClose = onClose;
      } else {
        this.onClose = null;
      }

      this.status = "CHECK_DEFAULT";

      this.resetPin();

      this.oldPin = this.pin;

      this.checkStatus();
    },

    initReset(onSuccess, onClose) {
      if (typeof onSuccess === "function") {
        this.onSuccess = onSuccess;
      } else {
        this.onSuccess = null;
      }

      if (typeof onClose === "function") {
        this.onClose = onClose;
      } else {
        this.onClose = null;
      }

      this.status = "REQUIRES_NEW_PIN";

      this.resetPin();

      this.oldPin = this.pin;

      this.checkStatus();
    },

    // Reseteo de PIN
    resetPin() {
      this.pin = "";
      this.progressbar = false;
    },

    // Checkeo el estado en el que se encuentra la solicitud de PIN
    checkStatus() {
      if (this.status === "CHECK_DEFAULT") {
        return this.checkDefault();
      }

      if (this.status === "ENTER_PIN") {
        return this.show();
      }

      if (this.status === "VALIDATE_PIN") {
        return this.validatePin();
      }

      if (this.status === "REQUIRES_NEW_PIN") {
        return this.show();
      }

      if (this.status === "REQUIRES_NEW_PIN_CONFIRMATION") {
        this.oldPin = this.pin;
        this.resetPin();
        return this.show();
      }

      if (this.status === "VALIDATE_NEW_PIN") {
        this.validateNewPin();
      }

      if (this.status === "SAVE_NEW_PIN") {
        this.saveNewPin();
      }
    },

    // Valido si el usuario posee el PIN por defecto
    checkDefault() {
      let self = this;

      telecentro.tplay.core.biz.parentalControl.checkDefaultPin(
        pinDefaultValue,
        function (ok) {
          // Si el usuario tiene seteado el PIN por default, le solicito que lo cambie
          self.status = "REQUIRES_NEW_PIN";

          self.checkStatus();
        },
        function (error) {
          // Caso contrario pido el ingreso de PIN para validar
          self.status = "ENTER_PIN";

          self.checkStatus();
        }
      );
    },

    // Valido las contraseñas ingresadas por el usuario
    validateNewPin() {
      if (this.oldPin == this.pin) {
        if (this.pin != pinDefaultValue) {
          this.status = "SAVE_NEW_PIN";
          this.checkStatus();
        } else {
          this.$bus.$emit(
            "show-toast",
            "error",
            "El Pin no puede ser el pin por defecto. Intente de nuevo con otro Pin por favor."
          );

          this.resetPin();

          this.oldPin = this.pin;

          this.status = "REQUIRES_NEW_PIN";
          this.checkStatus();
        }
      } else {
        this.$bus.$emit(
          "show-toast",
          "error",
          "Los Pines ingresados no coinciden. Intente de nuevo por favor."
        );

        this.resetPin();

        this.oldPin = this.pin;

        this.status = "REQUIRES_NEW_PIN";
        this.checkStatus();
      }
    },

    // Valido el PIN ingresado por el usuario por el existente en Alpha
    validatePin() {
      if (this.pin.length == 4) {
        var self = this;

        this.progressbar = true;

        telecentro.tplay.core.biz.parentalControl.validateAdultCode(
          self.pin,
          (ok) => {
            this.$bus.$emit("checkStatusParentalControl", ok.status);

            if (self.onSuccess) {
              setTimeOutParentalControl();
              self.onSuccess();
            }
            self.execCloseCallback = false;
            self.close();
          },
          function (error) {
            self.$bus.$emit(
              "show-toast",
              "error",
              "Código Pin inválido. Intente de nuevo por favor."
            );

            self.resetPin();
          }
        );
      }
    },

    // Envía a Alpha el nuevo pin
    saveNewPin() {
      let self = this;

      this.progressbar = true;

      telecentro.tplay.core.biz.parentalControl.setAdultCode(
        self.pin,
        function (ok) {
          self.progressbar = false;

          self.status = "CHECK_DEFAULT";

          self.resetPin();

          if (self.onSuccess) {
            setTimeOutParentalControl();
            self.onSuccess();
          }
          self.execCloseCallback = false;
          self.close();
        },
        function (error) {
          self.progressbar = false;

          self.status = "CHECK_DEFAULT";

          self.$bus.$emit(
            "show-toast",
            "error",
            "No se pudo modificar tu código Pin. Intente de nuevo por favor."
          );

          self.resetPin();

          self.oldPin = self.pin;

          self.checkStatus();
        }
      );
    },

    // Muestro el modal
    show() {
      let self = this;

      showModal(
        ".modal-pin",
        function () {
          self.$el.focus();
          self.$el.addEventListener("keyup", self.keyboardControls, false);
        },
        function () {
          self.close();
          if (self.execCloseCallback && self.onClose) {
            self.onClose();
          }
          self.execCloseCallback = true;
        }
      );
    },

    // Oculto el modal
    close() {
      let self = this;
      self.$el.removeEventListener("keyup", self.keyboardControls);
      self.$el.querySelector(".close")?.click();
      self.resetPin();
    },

    // Finalizó el ingreso del PIN en el modal
    inputCompleted() {
      if (this.status === "ENTER_PIN") {
        this.status = "VALIDATE_PIN";
      } else if (this.status === "REQUIRES_NEW_PIN") {
        this.status = "REQUIRES_NEW_PIN_CONFIRMATION";
      } else if (this.status === "REQUIRES_NEW_PIN_CONFIRMATION") {
        this.status = "VALIDATE_NEW_PIN";
      }

      this.checkStatus();
    },

    // Pulsación de teclas
    keyboardControls(key) {
      if (!this.progressbar) {
        // Si pulsé la tecla de los números
        if (
          (key.keyCode >= 48 && key.keyCode <= 57) ||
          (key.keyCode >= 96 && key.keyCode <= 105)
        ) {
          // Controlo que el pin tenga menos de 4 dígitos
          if (this.pin.length < 5) {
            this.pin += key.key;

            if (this.pin.length == 4) {
              this.inputCompleted();
            }
          }
        }

        // Si pulsé la tecla de borrado
        if (key.keyCode == 8) {
          this.pin = this.pin.substring(0, this.pin.length - 1);
        }
      }
    },

    isModalPinOpened() {
      return document.querySelector(".modal-pin > .expanded");
    },
  },
};
</script>
